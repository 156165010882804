@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-size: 20px;
  margin: 0;
  min-height: calc(100vh - 50px);
}

@media (max-width: 768px) {
  .App {
    font-size: 16px;
  }
}

Footer {
  height: 50px;
}

.footer {
  font-weight: 600;
  color: darkgreen;
  text-align: center;
  background: #eeeeee;
  padding-bottom: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.footer p {
  margin: 0 20px;
}

.header {
  font-family: "Roboto", sans-serif;
  color: darkgreen;
  margin: auto;
  width: 50%;
  font-size: 20px;
  box-shadow: 0 0 5px 5px;
}

.header h1 {
  margin: 30px;
  margin-bottom: 10px;
  font-size: 70px;
}

@media (max-width: 768px) {
  .header h1 {
    font-size: 30px;
    padding-top: 10px;
  }
}

.header > p {
  margin-top: 10px;
  padding-bottom: 15px;
}

.about-book {
  color: black;
  padding-top: 30px;
  margin: auto;
  width: 40%;
  margin-bottom: 50px;
  text-align: left;
  text-indent: 2em;
}

.book-cover-center {
  display: flex;
}

.bookcover {
  width: 40%;
  margin: auto;
  padding: 10px;
}

.about-book h2 {
  text-align: center;
}

@media (max-width: 768px) {
  .about-book {
    width: 80%;
  }
}

.headpicture2 {
  float: right;
  width: 50%;
  margin: 10px;
}

.author {
  padding-top: 30px;
  margin: auto;
  width: 40%;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .author {
    width: 80%;
    margin: auto;
  }
  .figure3 {
    position: relative !important;
    padding-left: 0 !important;
  }
  .rich {
    width: 70% !important;
  }
}

h2 {
  margin-top: 0;
}

.figure3 {
  padding-left: 30px;
  padding-bottom: 10px;
  margin: auto;
  width: 300px;
  float: right;
}
.figure3 .rich {
  width: 100%;
}

.author > p {
  text-align: left;
  text-indent: 2em;
  margin-top: 0;
}

.home {
  color: black;
  padding-top: 30px;
  margin: auto;
  width: 40%;
  margin-bottom: 50px;
}

.figure1 {
  width: 40%;
  float: left;
  padding: 0;
  padding-top: 20px;
}

@media (max-width: 768px) {

  .figure1,
  .figure2 {
    width: 50%;
    margin: auto;
    padding: 10px 20px;
  }
  .caption1 {
    font-size: 20px;
  }
  .home {
    text-align: center;
    width: 80% !important;
  }
}

.figure1 .frontpicture {
  width: 100%;
}

.caption1 {
  color: darkgreen;
}

.text > p {
  text-align: left;
  text-indent: 2em;
  margin-top: 0;
}

.second-section {
  /* width: 50%; */
  display: flex;
  /* float: right; */
  justify-content: space-around;
}

@media (max-width: 1342px) {
  .second-section {
    width: 100%;
    float: none;
    justify-content: center;
  }
  .headpicture1 {
    width: 70% !important;
  }
}

@media (max-width: 768px) {
  .second-section {
    display: block;
  }
}

.poem {
  font-size: 18px;
  width: 50%;
  margin-bottom: 10px;
}

.figure2 {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headpicture1 {
  width: 100%;
  margin-bottom: 20px;
}

.third-section {
  text-align: left;
  text-indent: 2em;
  margin-top: 0;
}

.third-section h2 {
  text-align: center;
}

h2,
h3,
h4 {
  color: darkgreen;
}

.links {
  margin: auto;
  margin-top: 50px;
  width: 50%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0;
}

li {
  width: 200px;
}

a {
  text-decoration: none;
  border-bottom: 2px solid #000000;
  font-family: "Roboto", sans-serif;
  color: darkgreen;
  font-size: 20px;
  padding: 10px;
}

@media (max-width: 1000px) {
  * {
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  a {
    font-size: 14px;
  }
  .links {
    width: 70%
  }
}

a:hover {
  color: black;
}

.active {
  background-color: lightgray;
}

