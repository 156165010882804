.links {
  margin: auto;
  margin-top: 50px;
  width: 50%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0;
}

li {
  width: 200px;
}

a {
  text-decoration: none;
  border-bottom: 2px solid #000000;
  font-family: "Roboto", sans-serif;
  color: darkgreen;
  font-size: 20px;
  padding: 10px;
}

@media (max-width: 1000px) {
  * {
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  a {
    font-size: 14px;
  }
  .links {
    width: 70%
  }
}

a:hover {
  color: black;
}

.active {
  background-color: lightgray;
}
