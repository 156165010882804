.home {
  color: black;
  padding-top: 30px;
  margin: auto;
  width: 40%;
  margin-bottom: 50px;
}

.figure1 {
  width: 40%;
  float: left;
  padding: 0;
  padding-top: 20px;
}

@media (max-width: 768px) {

  .figure1,
  .figure2 {
    width: 50%;
    margin: auto;
    padding: 10px 20px;
  }
  .caption1 {
    font-size: 20px;
  }
  .home {
    text-align: center;
    width: 80% !important;
  }
}

.figure1 .frontpicture {
  width: 100%;
}

.caption1 {
  color: darkgreen;
}

.text > p {
  text-align: left;
  text-indent: 2em;
  margin-top: 0;
}

.second-section {
  /* width: 50%; */
  display: flex;
  /* float: right; */
  justify-content: space-around;
}

@media (max-width: 1342px) {
  .second-section {
    width: 100%;
    float: none;
    justify-content: center;
  }
  .headpicture1 {
    width: 70% !important;
  }
}

@media (max-width: 768px) {
  .second-section {
    display: block;
  }
}

.poem {
  font-size: 18px;
  width: 50%;
  margin-bottom: 10px;
}

.figure2 {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headpicture1 {
  width: 100%;
  margin-bottom: 20px;
}

.third-section {
  text-align: left;
  text-indent: 2em;
  margin-top: 0;
}

.third-section h2 {
  text-align: center;
}

h2,
h3,
h4 {
  color: darkgreen;
}
