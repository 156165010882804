.about-book {
  color: black;
  padding-top: 30px;
  margin: auto;
  width: 40%;
  margin-bottom: 50px;
  text-align: left;
  text-indent: 2em;
}

.book-cover-center {
  display: flex;
}

.bookcover {
  width: 40%;
  margin: auto;
  padding: 10px;
}

.about-book h2 {
  text-align: center;
}

@media (max-width: 768px) {
  .about-book {
    width: 80%;
  }
}

.headpicture2 {
  float: right;
  width: 50%;
  margin: 10px;
}
