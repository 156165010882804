.footer {
  font-weight: 600;
  color: darkgreen;
  text-align: center;
  background: #eeeeee;
  padding-bottom: 20px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.footer p {
  margin: 0 20px;
}
