.author {
  padding-top: 30px;
  margin: auto;
  width: 40%;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .author {
    width: 80%;
    margin: auto;
  }
  .figure3 {
    position: relative !important;
    padding-left: 0 !important;
  }
  .rich {
    width: 70% !important;
  }
}

h2 {
  margin-top: 0;
}

.figure3 {
  padding-left: 30px;
  padding-bottom: 10px;
  margin: auto;
  width: 300px;
  float: right;
}
.figure3 .rich {
  width: 100%;
}

.author > p {
  text-align: left;
  text-indent: 2em;
  margin-top: 0;
}
