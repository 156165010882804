@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,900&display=swap");

.header {
  font-family: "Roboto", sans-serif;
  color: darkgreen;
  margin: auto;
  width: 50%;
  font-size: 20px;
  box-shadow: 0 0 5px 5px;
}

.header h1 {
  margin: 30px;
  margin-bottom: 10px;
  font-size: 70px;
}

@media (max-width: 768px) {
  .header h1 {
    font-size: 30px;
    padding-top: 10px;
  }
}

.header > p {
  margin-top: 10px;
  padding-bottom: 15px;
}
