.App {
  text-align: center;
  font-size: 20px;
  margin: 0;
  min-height: calc(100vh - 50px);
}

@media (max-width: 768px) {
  .App {
    font-size: 16px;
  }
}

Footer {
  height: 50px;
}
